<template>
  <header
    class="new-header"
    ref="newHeader"
    :class="[
      {active: navActive},
      {
        sub:
          $route.name === 'consulting' ||
          $route.name === 'about' ||
          $route.name === 'works-list' ||
          $route.name === 'works-detail'
      }
    ]"
  >
    <div class="new-header-inner">
      <div class="bg"></div>
      <div class="nav">
        <div class="nav-inner">
          <h1 class="logo">
            <a href="/" @click.prevent="goHome">
              <span class="blind">emotion</span>
              <!-- <img :src="require('@/assets/images/pc_logo.svg')" alt="" />
              <img :src="require('@/assets/images/pc_logo_wh.svg')" alt="" /> -->
              <!--          <svg-->
              <!--            viewBox="0 0 283.5 50.6"-->
              <!--            x="0"-->
              <!--            y="0"-->
              <!--            xml:space="preserve"-->
              <!--            xmlns="http://www.w3.org/2000/svg"-->
              <!--          >-->
              <!--            <path-->
              <!--              class="logo-emotion"-->
              <!--              d="M143.6 15.6c-9.7 0-17.5 7.8-17.5 17.5s7.8 17.5 17.5 17.5 17.5-7.8 17.5-17.5c0-9.6-7.8-17.5-17.5-17.5m.4 24.6c-3.9 0-7-3.2-7-7 0-3.9 3.2-7 7-7 3.9 0 7 3.2 7 7s-3.1 7-7 7M226.5 15.6c-9.7 0-17.5 7.8-17.5 17.5s7.8 17.5 17.5 17.5S244 42.8 244 33.1c0-9.6-7.8-17.5-17.5-17.5m.4 24.6c-3.9 0-7-3.2-7-7 0-3.9 3.2-7 7-7 3.9 0 7 3.2 7 7s-3.1 7-7 7M109.3 30.3c0-2.5-1.2-4.5-4.4-4.5-2.9 0-5 1.9-6 3.2v21.5H88.2V30.3c0-2.5-1.2-4.5-4.4-4.5-2.8 0-4.8 1.9-5.9 3.2v21.5H67.3V17.3h10.6v4.1c1.4-1.9 5.7-4.9 11-4.9 4.8 0 8.1 2 9.4 5.9 1.9-2.9 6.3-5.9 11.5-5.9 6.1 0 10 3.2 10 10v24.1h-10.6V30.3zM169.6 50.6v-24h-5.8v-9.3h5.8v-6.8h10.5v6.8h5.8v9.3h-5.8v24M272.8 31.4c0-4.1-2.1-5.5-5.4-5.5-3.2 0-5.2 1.7-6.5 3.2v21.5h-10.6V17.3h10.6v4.1c2-2.3 5.9-4.9 11.5-4.9 7.6 0 11 4.4 11 10.6v23.6h-10.6V31.4zM58.7 33.1c0 3.9-3.2 7-7 7-3.9 0-7-3.2-7-7 0-3.9 3.2-7 7-7s7 3.1 7 7M192.2 17.3h10.6v33.3h-10.6zM204.8 7c0 3.9-3.2 7-7 7-3.9 0-7-3.2-7-7 0-3.9 3.2-7 7-7s7 3.2 7 7M18 14.6c-10 0-18 8.1-18 18 0 10 8.1 18 18 18 7.8 0 14.4-5 16.9-11.9H23.1c-1.4 1.2-3.1 1.9-5.1 1.9-3.1 0-5.8-1.8-7.1-4.4H35.7c.2-1.1.4-2.3.4-3.5 0-10.1-8.1-18.1-18.1-18.1m-7.3 14.8c1.2-2.9 4-4.9 7.3-4.9s6.1 2 7.3 4.9H10.7z"-->
              <!--            />-->
              <!--          </svg>-->
            </a>
          </h1>
          <!-- <h2 class="page-title" v-if="title">
            <router-link :to="`/${title.toLowerCase()}`">{{ title }}</router-link>
          </h2> -->
          <ul class="gnb">
            <li :class="{current: $route.name === 'consulting'}">
              <router-link to="/consulting" @click.native="$emit('update:navActive', false)">
                Consulting
              </router-link>
            </li>
            <li :class="{current: $route.name === 'works-list' || $route.name === 'works-detail'}">
              <router-link to="/works" @click.native="$emit('update:navActive', false)">
                Projects
              </router-link>
            </li>
            <li
              :class="{
                current:
                  $route.name === 'magazine-list' ||
                  $route.name === 'magazine-detail' ||
                  $route.name === 'magazine-item'
              }"
            >
              <router-link to="/magazine" @click.native="$emit('update:navActive', false)">
                Magazine
              </router-link>
            </li>
            <li :class="{current: $route.name === 'careers'}">
              <router-link to="/careers" @click.native="$emit('update:navActive', false)">
                Careers
              </router-link>
            </li>
            <li :class="{current: $route.name === 'request'}">
              <router-link to="/request" @click.native="$emit('update:navActive', false)">
                Request
              </router-link>
            </li>
            <li :class="{current: $route.name === 'about'}">
              <router-link to="/about" @click.native="$emit('update:navActive', false)">
                About
              </router-link>
            </li>
          </ul>

          <!-- <button class="nav-toggle" type="button" @click="$emit('update:navActive', !navActive)">
            <span></span>
          </button> -->
        </div>
      </div>
    </div>
  </header>
</template>
<script>
  import bus from "@/utils/bus";

  export default {
    name: "AppHeader",
    props: ["navActive"],
    computed: {
      title() {
        return this.$route.matched[0]?.meta?.title;
      }
    },
    mounted() {
      window.removeEventListener("scroll", this.headerScroll);
      window.addEventListener("scroll", this.headerScroll);
    },
    methods: {
      goHome() {
        if (this.$route.path === "/") {
          bus.$emit("winScrollTo", 0, 0);
        } else {
          this.$router.push("/");
        }
        this.$emit("update:navActive", false);
      },
      headerScroll() {
        const scrollY = window.scrollY || window.pageYOffset;
        const header = this.$refs.newHeader;
        if (scrollY > 100) {
          header.classList.add("header-fixed");
        } else {
          header.classList.remove("header-fixed");
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  .new-header {
    position: fixed;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100vw;
    z-index: 100;
    overflow: hidden;
    //pointer-events-none

    .new-header-inner {
      position: fixed;
      top: 36px;
      left: 50%;
      display: flex;
      align-items: center;
      width: 100vw;
      height: 68px;
      max-width: 1200px;
      border-radius: 34px;
      transform: translate(-50%, 0);
      transition: border 0.1s ease-in, width 0.2s ease-out;
    }

    .bg {
      position: absolute;
      inset: 0;
      border-radius: 34px;
      background-color: #eeeeee;
      opacity: 0;
      transition: opacity 0.2s linear;
      z-index: -1;
    }

    .nav {
      position: relative;
      width: 100%;
      padding: 0 10px;
      transition: width 0.2s ease-out;

      .nav-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .logo {
        position: relative;
        color: blue;
        width: 103px;
        height: 18px;
        margin-left: 20px;
        background: url(../assets/images/common/logo.svg) no-repeat center / contain;
        a {
          display: block;
          width: 100%;
          height: 100%;
          overflow: hidden;
          text-indent: -999px;
        }
      }

      .gnb {
        display: flex;
        a {
          position: relative;
          display: inline-flex;
          padding: 16px 18px;
          color: #000;
          font-family: "Montserrat", sans-serif;
          font-weight: 500;
          font-size: 16px;
          transition: 0.3s;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 24px;
            background: transparent;
            transition: 0.3s ease-in-out;
            z-index: -1;
          }
        }
        .current a {
          border-radius: 24px;
          color: #fff;
          &::before {
            background: $red;
          }

          &:hover {
            &::before {
              transform: scale(0);
            }
          }
        }

        a:hover {
          color: $red;
        }
      }
    }

    &.header-fixed {
      .new-header-inner {
        width: 844px;
        border-radius: 34px;
        backdrop-filter: blur(3px);
        transition: border 0.2s ease-in, width 0.2s ease-out;
      }
      .bg {
        opacity: 0.9;
      }

      .logo {
        margin-left: 50px;
      }
    }

    &.sub:not(.header-fixed) {
      .nav .gnb a {
        color: #eee;
      }
    }
  }
</style>
