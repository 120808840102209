var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{ref:"newHeader",staticClass:"new-header",class:[
    {active: _vm.navActive},
    {
      sub:
        _vm.$route.name === 'consulting' ||
        _vm.$route.name === 'about' ||
        _vm.$route.name === 'works-list' ||
        _vm.$route.name === 'works-detail'
    }
  ]},[_c('div',{staticClass:"new-header-inner"},[_c('div',{staticClass:"bg"}),_c('div',{staticClass:"nav"},[_c('div',{staticClass:"nav-inner"},[_c('h1',{staticClass:"logo"},[_c('a',{attrs:{"href":"/"},on:{"click":function($event){$event.preventDefault();return _vm.goHome.apply(null, arguments)}}},[_c('span',{staticClass:"blind"},[_vm._v("emotion")])])]),_c('ul',{staticClass:"gnb"},[_c('li',{class:{current: _vm.$route.name === 'consulting'}},[_c('router-link',{attrs:{"to":"/consulting"},nativeOn:{"click":function($event){return _vm.$emit('update:navActive', false)}}},[_vm._v(" Consulting ")])],1),_c('li',{class:{current: _vm.$route.name === 'works-list' || _vm.$route.name === 'works-detail'}},[_c('router-link',{attrs:{"to":"/works"},nativeOn:{"click":function($event){return _vm.$emit('update:navActive', false)}}},[_vm._v(" Projects ")])],1),_c('li',{class:{
              current:
                _vm.$route.name === 'magazine-list' ||
                _vm.$route.name === 'magazine-detail' ||
                _vm.$route.name === 'magazine-item'
            }},[_c('router-link',{attrs:{"to":"/magazine"},nativeOn:{"click":function($event){return _vm.$emit('update:navActive', false)}}},[_vm._v(" Magazine ")])],1),_c('li',{class:{current: _vm.$route.name === 'careers'}},[_c('router-link',{attrs:{"to":"/careers"},nativeOn:{"click":function($event){return _vm.$emit('update:navActive', false)}}},[_vm._v(" Careers ")])],1),_c('li',{class:{current: _vm.$route.name === 'request'}},[_c('router-link',{attrs:{"to":"/request"},nativeOn:{"click":function($event){return _vm.$emit('update:navActive', false)}}},[_vm._v(" Request ")])],1),_c('li',{class:{current: _vm.$route.name === 'about'}},[_c('router-link',{attrs:{"to":"/about"},nativeOn:{"click":function($event){return _vm.$emit('update:navActive', false)}}},[_vm._v(" About ")])],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }